.admin__container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
}


.admin__layout {
  width: 100vw;
  background: #f8f8f8;
  height: 100vh;
  position: relative;
}

.side__nav {
  width: 242px;
  height: 100vh;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
}

.side__nav_header {
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.side__nav_header h1 {
  font-family: Antonio;
  margin: 0 !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 25.88px;
  text-align: left;
  color: #000000;
}

.side__nav_header h1 span {
  color: #2fa566;
  font-family: Antonio;
  margin: 0 !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 25.88px;
}

.side__nav_content {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.side__nav_content_item_link {
  width: 100%;
}

.side__nav_content_item {
  width: 100%;
  display: flex;
  gap: 37px;
  height: 51px;
}

.side__nav_content_item_bar {
  width: 3px;
  height: 51px;
  border-radius: 2px;
}

.side__nav_content_item_select {
  width: 177px;
  display: flex;
  align-items: center;
  height: 51px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  backdrop-filter: blur(50px);
  transition: 0.3s all;
}

.side__nav_content_item:hover .side__nav_content_item_select,
.side__nav_content_item:hover .side__nav_content_item_bar {
  background: #2fa566;
}

.active {
  background: #2fa566;
}

.nav__icon {
  color: #464646;
}

.side__nav_content_item:hover .nav__icon,
.side__nav_content_item:hover .nav__text {
  color: #ffffff;
}

.nav__text_active {
  color: #ffffff !important;
}
.nav__text {
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #464646;
}

.admin__layout_body {
  width: calc(100% - 242px);
  transform: translateX(242px);
  height: 100vh;
  position: relative;
  padding-bottom: 60px;
}

.admin__layout_body_top_nav {
  width: 100%;

  background: #ffffff;

  padding: 0 40px;
}

.top__nav_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.top__nav_search_bar {
  width: 435px;
  height: 42px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #00000026;
  background: #00000005;
  display: flex;
  align-items: center;
}

.search__input {
  outline: none;
  border: none;
  background: transparent;
  flex: 1;
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000000;
}

.search__input::placeholder {
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #00000033;
}

.top__nav_right {
  height: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
}

.top__nav_right_icon_box {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #0000001a;
}

.top__nav_right_divider {
  border-left: 1px solid #0000001a;
  width: 1px;
  height: 36px;
}

.top__nav_right_user {
  display: flex;
  gap: 6.5px;
  align-items: center;
}

.top__nav_right_user_image {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid #0000001a;
}

.top__nav_right_user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top__nav_right_user p {
  font-family: Lato;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #000000cc;
}

.admin__layout_body_content {
  height: calc(100vh - 64px);
  padding: 32px 40px;
  overflow-y: scroll;
  background: #f8f8f8;
}

.admin__content {
  width: 100%;
  background: #f8f8f8;
}

.admin__content_header {
  font-family: Antonio;
  margin: 0 !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.05px;
  text-align: left;
  color: #000000;
  margin-bottom: 24px;
}

.admin__content_body {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: column;
  background: #f8f8f8;
}

.admin__cards {
  width: 100%;
  display: flex;
  gap: 25px;
}

.admin__card {
  width: 23%;
  height: 160px;
  border: 1px solid #0000000d;
  box-shadow: 0px 2px 2px 0px #2c7c5112;
  background: #ffffff;
  padding: 16px;
}

.admin__card_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin__card_content_title {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.admin__card_content_title_text {
  font-family: Lato;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
}

.admin__card_content_title_icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8280ff;
  opacity: 0.21;
  border-radius: 48px;
}

.admin__card_content_value {
  font-family: Lato;
  margin: 0 !important;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
  color: #000000;
}

.admin__card_content_footer {
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: flex-end;
}

.admin__card_content_footer_value {
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #fd315d;
}

.admin__card_content_footer_value span {
  color: #000000;
}

.line__chart {
  width: 100%;
  height: 410px;
  min-height: 410px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: #ffffff;
  border: 1px solid #0000000d;
  box-shadow: 0px 2px 2px 0px #2c7c5112;
  border-radius: 8px;
  box-sizing: border-box;
}

.line__chart_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.select {
  background: transparent;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.type {
  font-family: Lato;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
}

.line__chart_header_select {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #00000005;
  border: 1px solid #0000001a;
  padding: 8px 10px;
  border-radius: 4px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.line__chart_header_select_text {
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  color: #000000;
}

.line__chart_header_select_options {
  width: 200px;
  height: 297px;
  top: 50px;
  left: 0;
  border-radius: 12px;
  border: 1px solid #0000001a;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: absolute;
}

.line__chart_header_select_option {
  width: 100%;
  height: 49px;
  border-bottom: 1px solid #0000001a;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  padding: 0 16px;
  /* flex-direction: column; */
}
.line__chart_header_select_option p {
  font-family: Lato;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  color: #000000;
}

.line__chart_chart {
  width: 100%;
  height: 278px;
}

.table__container {
  padding: 20px;
  font-family: Arial, sans-serif;
  & table {
    width: 100%;
    border-collapse: collapse;
  }

  & th {
    background: #2fa5660d;
  }

  & th,
  & td {
    padding: 12px;
    text-align: left;
    color: #000000;
    width: 199px;
  }

  & th {
    background-color: #2fa5660d;
    font-weight: bold;
    font-family: Lato;
    margin: 0 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  & tr:nth-child(even) {
    /* background-color: #f9f9f9; */
  }

  & tr:hover {
    /* background-color: #f1f1f1; */
  }

  & td {
    font-family: Lato;
    margin: 0 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-style: solid;
    border-color: #0000000d;
    border-width: 0px 0px 1px;
  }

  & td:nth-child(5),
  & th:nth-child(5) {
    width: 300px;
  }

  & button {
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 0 !important;
    font-size: 14px;
    margin-right: 5px;
  }
}

.admin__button {
  width: fit-content;
  padding: 12px 32px;
  border-radius: 12px;
  opacity: 0px;
  text-align: center;
  outline: none;
  border: none;
  background: #2c7c51;
  font-family: Antonio;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  cursor: pointer;
}

/* Buttons */

.approve__button {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.reject__button {
  background-color: #f44336;
  color: white;
  border: 1px solid #f44336;
}

/* Status Labels */
.approved__status {
  display: inline-block;
  background-color: #8b572a;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.rejected__status {
  display: inline-block;
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Links */
.view__details__link {
  color: #007bff;
  text-decoration: none;
  margin: 0 !important;
  font-size: 14px;
}

.view__details__link:hover {
  text-decoration: underline;
}
